<template>
  <div class="column-center">
    <div style="position: relative">
      <img :src="icons.group_banner89" class="full-width" />
      <video
        :src="icons.group_video_artist89"
        class="group-content-width group-video"
        style="width: 70vw"
        controls
      ></video>
    </div>

    <!-- <span class="group-title-white group-content-width">艺术家详情</span> -->

    <div class="column group-content-width" style="margin: 55px 0">
      <span class="group-title">埃马纽埃尔·热娜徳 作品</span>
      <img :src="icons.group_arrow" width="66" style="margin-top: 60px" />

      <div class="row" style="margin-top: 50px; justify-content: center">
        <img
          :src="icons.group_artist89p1"
          width="392"
          style="margin-right: 51px"
        />
        <img :src="icons.group_artist89p2" width="178" />
      </div>
    </div>

    <!-- <span class="group-page-english" style="margin-top: 80px">8-9ans</span>
    <span class="group-page-title"> 8-9岁组联创艺术家 </span>
    <img :src="icons.intro_triangle2" height="15" style="margin-top: 10px; margin-bottom: 55px" /> -->

    <div class="full-width column-center group-art-container">
      <div class="row" style="margin-bottom: 35px">
        <img :src="icons.group_triangle_left" width="15" />
        <span class="group-art-title"> 埃马纽埃尔·热娜徳 </span>
        <img :src="icons.group_triangle_right" width="15" />
      </div>

      <img
        :src="icons.group_artist89"
        width="450"
        style="margin-bottom: 68px"
      />

      <span class="group-art-desc group-content-width"
        >画家、设计师、雕刻师、陶艺家，是当今法国舞台上的一位重要的具象艺术家，她自
        1986
        年以来一直在法国和其他国家举办画展：巴黎、汉堡、法兰克福、卡尔斯鲁厄、伦敦、布鲁塞尔、哥本哈根、日内瓦、巴塞尔、斯德哥尔摩、芝加哥、洛杉矶、贝鲁特......
        三十多年来，她吸引了众多法国和国外的收藏家。</span
      >
      <span class="group-art-desc group-content-width"
        >对于定义她的画作，我们可以称之为超现实表现主义，反之亦然。因为它是一种强烈、有力、极具表现力的画。在她的画作中，物体似乎被赋予了生命，有着古怪的人物，几乎不可能存在的场景以及极其纯粹的色彩。如果一定要给这种画作找到一个起源，我们会很乐意提及巴洛克艺术家，然后是戈雅、苏丁、夏加尔和雷贝罗尔。</span
      >
    </div>
  </div>
</template>

<script>
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
};
</script>

<style scoped>
.group-content-width {
  width: 800px;
}

.group-title-white {
  font-family: PingFangSC-Medium;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: -1.23px;
  margin-top: -85px;
}

.group-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
}

.group-page-english {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.group-page-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}

.group-art-container {
  padding-top: 80px;
  padding-bottom: 150px;
  background-color: #ff3000;
}
.group-art-title {
  font-family: PingFangSC-Semibold;
  font-size: 48px;
  color: #ffffff;
  letter-spacing: -1.65px;
  margin: 0 35px;
}

.group-art-desc {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: -0.82px;
  text-align: center;
}
</style>